import React from 'react'
import auth from "../assets/images/unauthorized.png"

const Forbbiden = () => {
 

  return (
    <div style={{ height: "100vh",  }} className=" w-100 forbbiden text-white  d-flex flex-column align-items-center justify-content-center border">
      <img src={auth} className='h-50 '/>
    <h4 >Access Denied</h4>
    <h4 >You are accessing unauthorized page.</h4>
    <h4 >Please go to <a href="/" className='text-danger'>login.</a></h4>
    {/* <p>access not granted</p>
    <p>Please contact Admin</p>
    <button className='p-2' style={{ backgroundColor: "#74a3ed" }} onClick={() => { navigate('/login') }}>Go to Login</button> */}
</div>
  )
}

export default Forbbiden