import "./App.css";
import React, { useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Base_Url, urls } from "./configFiles/url";
import { getList } from "./configFiles/sharedFuctions";
import Login from "./pages/Login/login";
import CryptoJS from "crypto-js";
import Forbbiden from "./pages/Forbbiden";
import CreatePassword from "./pages/Login/CreatePassword";
import { updateMessages } from "./pages/ReduxStore/Action";
import { HubConnectionBuilder } from "@microsoft/signalr";
import axios from "axios";
import { useState } from "react";
const MainPage = lazy(() => import("./pages/MainPage"));
const PrivateRouting = lazy(() => import("./pages/PrivateRouting"));
const ClientRoute = lazy(() => import("./pages/ClientModule/ClientRoute"));
const PrivateRouteClient = lazy(() => import("./pages/PrivateRouteClient"));

const App = () => {
  const dispatch = useDispatch();
  let clentId = useSelector((state) => state.selectedChatId);
  const messages = useSelector((state) => state.messages);

  const alldata = useRef(null);
  alldata.current = messages;
  const clientId = useRef(0);
  const userId = useRef(null);
  clientId.current = clentId;
  userId.current = useSelector((state) => state.loggedInUserId);

  const accessLinksStatus = useSelector((state) => state.accessLinks);

  const accesslinks = async () => {
    let jsonString = {
      subscriptionId: Number(sessionStorage.getItem("subscriptionId")),
    };
    let res2 = await getList(urls?.accessLevel?.getAllActiveLinkName, {
      jsonString,
    });
    const obj2 = ["Veterinary"];
   
    res2?.map((v) => {
      obj2?.push(v);
    });

    let encryptedtext = CryptoJS.AES.encrypt(
      JSON.stringify(obj2),
      
    ).toString();
    sessionStorage.setItem("accessLinks", encryptedtext);
  };

  const chartGetById = async () => {
    let jsonString = { userId: sessionStorage.getItem("userId") };

    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    };

    try {
      let res = await axios.post(
        Base_Url + urls?.chart?.chartGetById,
        { jsonString },
        { headers }
      );
      if (res?.data?.status == true) {
        dispatch(updateMessages(res.data.data || []));
      } else {
        dispatch(updateMessages([]));
      }
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
   
    if (userId.current > 0) {
      chartGetById();
    }
  }, [userId.current]);

  useEffect(() => {
    let subscriptionId = sessionStorage.getItem("subscriptionId");
    if (subscriptionId && subscriptionId != null) {
      accesslinks();
    }
  }, [accessLinksStatus]);

  useEffect(() => {
    if (userId.current > 0) {
      let connection = new HubConnectionBuilder()
        .withUrl("https://vmsapi.hitechhealth.us/chatHub")
        .build();
      connection
        .start()
        .then(function() {
          connection.on("ReceiveChat", (chatMessage) => {
            if (chatMessage?.[0]?.toUser == sessionStorage?.getItem("userId")) {
              const updatedChat = [...alldata?.current];
              updatedChat.push(chatMessage[0]);
              dispatch(updateMessages(updatedChat));
            }
          });
        })
        .catch(function(err) {});
    }
  }, [userId.current]);

  return (
    <div className="myApp">
      <ToastContainer />

      <Suspense fallback={<h5>Loading ....</h5>}>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Login />}></Route>
            <Route
              path="/forgotpassword/:userId"
              element={<CreatePassword />}
            ></Route>
            <Route
              exact
              path="/*"
              element={
                <PrivateRouting link="Veterinary">
                  <MainPage />
                </PrivateRouting>
              }
            />
            <Route
              path="/veterinary/client/*"
              element={
                <PrivateRouteClient>
                  <ClientRoute />
                </PrivateRouteClient>
              }
            />
            <Route path="/forbidden" element={<Forbbiden />} />
            <Route path="*" element={<Forbbiden />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;
