import instance from ".";



export const getData = async (url) => {
    try {
        let res = await instance.post(url,{});
        return res
    } catch (e) {
        console.log(e);
        return "";
    }
};
