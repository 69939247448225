
import { useEffect } from 'react';
import { useState } from 'react';
import { UseFormValidations } from '../validations/UseFormValidations';
import { useNavigate, useParams } from 'react-router-dom';
import { notify, save } from '../../configFiles/sharedFuctions';
import { urls } from '../../configFiles/url';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import DogImg from "../../assets/images/vms_Dog.png"

const CreatePassword = () => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const [show, setShow] = useState({ new: true, conform: true })


  const submit = async () => {
    if (data?.Password && data?.Password == data?.ConfirmPassword) {
      data["token"] = userId
      let res = await save(urls?.client.changePassword, data)
      if (res?.data?.status == true) {
        navigate("/")
      }
    }
    else {
      notify(false, "Password and Confirm Password should be same!!")
    }

  };
  useEffect(() => {
    sessionStorage?.clear()
  }, [])
  const { data, errors, setValues, handleChange, handleSubmit, handleEmailChange } = UseFormValidations({
    initialValues: {

      Password: "",
      ConfirmPassword: "",
      notShowMsg: true
    },
    validationSchema: {


      ConfirmPassword: {
        required: {
          value: true,
          message: "Please enter confirm password",
        },

      },
      Password: {
        required: {
          value: true,
          message: "Please enter new password",
        },
        minlength: {
          value: 8,
          message: "Password should contain one uppercase,one lowercase, one special character, one number and should be greater than 8 characters",

        }
        , pattern: {
          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
          message: "Password should contain one uppercase,one lowercase, one special character, one number and should be greater than 8 characters",

        }

      },
    },
    submit: submit
  })

  return (
    <div>
      <form onSubmit={handleSubmit}>
        
<div className="container-fluid loginpagebg">
        <div className="row ">
          <div className="col-md-6 d-flex justify-content-end ">
            <img src={DogImg} className="img" style={{ maxHeight: "46.7rem" }} ></img>
          </div>
          <div className="col-md-6   d-flex justify-content-center  d-flex align-items-center ">
            <div className=" p-4 col-md-8 border shadow rounded ">
            <div className="form-login">
              <div align="center">
                <img src="" />
              </div>

              <div className="form-group form-floating">
                <label htmlFor="floatingInput">New Password</label>
                <div className='d-flex'><input
                  type={show?.new == true ? "password" : "text"}
                  
                  className="form-control bg-white"
                  placeholder="Enter New Password"
                  id="email"
                  autoComplete="off"
                  name="New Password"
                  onChange={handleChange("Password")}
                />
                <span className='form-control col-md-1 rounded ms-2 px-2  ' onClick={() => setShow({ ...show, new: !show.new })}>
                  {show?.new == true ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}</span></div>

              </div>
              <div className="d-flex mr_25">
                {errors && errors?.Password && (
                  <p className="text text-danger">{errors?.Password}</p>
                )}
              </div>
              <div className="form-group form-floating ">
                <label for="pwd" htmlFor="floatingInput">
                  Confirm Password
                </label>
                <div className='d-flex'>
                <input
                  type={show?.conform == true ? "password" : "text"}

                 
                  className="form-control bg-white"
                  autoComplete="off"
                  name="ConfirmPassword"
                  onChange={handleChange("ConfirmPassword")}
                  placeholder="Enter Confirm Password"
                  id="pwd"
                />
                <span className='form-control col-md-1 rounded ms-2 px-2 ' onClick={() => setShow({ ...show, conform: !show.conform })}>{show.conform == true ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}</span>


                </div>
              </div>
              <div className="d-flex mr_25">
                {errors && errors?.ConfirmPassword && (
                  <p className="text text-danger">{errors?.ConfirmPassword}</p>
                )}
              </div>
              <label className='fornt-weight-bold'>Note</label>
              <p className='text-danger bg-white p-1'>
                Password should contain one uppercase,one lowercase, one special character, one number and should be greater than 8 characters
              </p>
              <div align="center">
                <button type="submit" className="btn button btn-block">
                  Submit
                </button>
              </div>
            </div>
            </div>
          </div>

        </div>
      </div>
      </form>
    </div>
  )
}

export default CreatePassword