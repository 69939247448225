import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import { Provider } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import Store from "./pages/ReduxStore/Index"
import AppStateProvider from "./Twilio/state";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

  
  <AppStateProvider>
    <Provider store={Store}>
    <App />
    </Provider>
    
  </AppStateProvider>
 
);
reportWebVitals();
