// export const Base_Url = "https://api.canoodle.ai/api/"
// export const Image_Base_Url = "https://api.canoodle.ai/assets"
// export const oppBaseUrl = "https://uatapi.oppunity.com/api/"
// export const twilioTokenUrl = "https://api.canoodle.ai/api/"
export const Base_Url = "https://vmsapi.hitechhealth.us/api/"
export const Image_Base_Url = "https://vmsapi.hitechhealth.us/assets"
export const oppBaseUrl = "https://uatapi.oppunity.com/api/"
export const twilioTokenUrl = "https://vmsapi.hitechhealth.us/api/"
// "homepage": "https://canoodle.ai/",
// "homepage": "https://vmsapi.hitechhealth.us",
export const urls = {
  oppUnity: {
    appointment: "Dashboard/VMSAppointments",
    credentialing: "Dashboard/VMSCredentialing",
    appointmentCount: "Dashboard/GetVMSAppointmentStatusCount",
    getFacilityDropdown: "Dashboard/GetActivatedList"
  },
  authenticate: {
    login: "Login/Login",
     forgotPassword:"Login/ForgotPassword", 
     resetPassword:"Login/ResetPassword"
  },
  patients: { 
    title:"NameTitle/GetAll",
    getAllPatients: "ClientPatient/GetAll",
    getAllEstimates: "Estimates/GetAll",
    getPatientGenderDropdown: "PatientSex/PatientGenderDropDown",
    addForm: "ClientPatient/Insert",
    species: 'Species/GetAll',
    speciesdd: 'Species/GetAllSpecies',
    bread: "Breed/GetAll",
    country: "Country/GetAll",
    insurance: "Insurance/GetByFacilityId",
    updateForm: "ClientPatient/Update",
    patientGetById: "ClientPatient/GetByClientId",
    getProcedures: "Procedure/GetAll",
    getFacilityList: "Facility/GetAllFacilities",
    getFacilityByOrganizationId: "Facility/FacilityByOrganizationId",
    tags: "Tags/GetAllTags",
    addtags: "Tags/SaveTags",
    clientTags:"ClientPatient/ClientTag",
    addClientTags:"ClientPatient/SaveClientTag",
    imageUpload: "ClientPatient/UploadPatientImage",
    patientstatus: "ClientPatient/UpdatePatientStatus",
    clientStatus: "ClientPatient/UpdateClientStatus",
    getReminderList: "SmartNotifications/SmartNotificationById",
    saveReminder: "SmartNotifications/SaveSmartNotification",
    patientBalance:"ClientPatient/PatientBalance",
    ClientDrivingLicense:"ClientPatient/ClientDrivingLicense",
    DeleteLicense:"ClientPatient/DeleteLicense"
  },

  reports: {
    getReportsDropDown: "Reports/GetAllDropDown",
    getAllReports: "Reports/SaveAllReports",
    downloadReports:"Reports/GenerateReport",
    downloadReportsforReports:"Reports/GenerateDevExpressReport"

  },
  estimates: {
    getClients: "Estimates/GetAllClients",
    saveStatus: "Estimates/UpdateStatus",
    getEmail: "Estimates/GetMails",
    getAppointmentByPatient: "Appointment/GetPatientAppointments",
    sendEmail: "Estimates/SendMail",
    getBundles: "Bundle/GetAll",
    getEstimateBundleItems: "Estimates/GetEstimateBundleItems",
    getProcedures: "Procedure/GetAll",
    getMedicine: "Medicine/GetAll",
    getFood: "Food/GetAll",
    getSupply: "Supply/GetAll",
    getAllItems: "Items/GetAllItems",
    getAllLabs: "LabTests/GetAllLabs",
    getLabTests: "LabTests/GetAllTestNames",
    saveEstimate: "Estimates/SaveEstimates",
    saveEstimateBundles: "Estimates/SaveEstimateBundle",
    saveEstimateItems: "Estimates/SaveEstimateItems",
    getAllEstimateItems: "Estimates/GetEstimateItems",
    GetEstimatesById: "Estimates/GetEstimatesById",
    UpdateEstimateQuantity: "Estimates/UpdateEstimateQuantity",
    discountType: "Discount/DiscountById",
    allEstimateData: "Estimates/SearchEstimateItems",
    //  master/estimate/labs
    createLabs: "Lab/Save",
    getAllLabs: "Lab/GetAll",
    LabGetById: "Lab/GetById",
    labStatus: "Lab/UpdateStatus",
    getActiveLabs: "Lab/GetAllDropDown",
    //  master/estimate/tests
    createLabTest: "LabTests/Save",
    getAllLabtests: "LabTests/GetAll",
    LabTestGetById: "LabTests/GetById",
    labTestStatus: "LabTests/UpdateStatus",
    getAlltest: "Test/GetAllDropDown",
    //test
    createTest: "Tests/Save",
    getAllTest: "Tests/GetAll",
    getByIdTest: "Tests/GetById",
    updateStatusTest: "Tests/UpdateStatus",
    ///lab and tests
    savetestByLabId: "Tests/LabTestSave",
    getTestByLabId: "Tests/GetLabTestById"
  },
  accessLevel: {
    getAllAccess: "Login/GetRecursiveAccessLinks",
    getAllaccessList: "Login/GetAllAccessLinks",
    getAllAccessRoles: "Login/GetAllRoles",
    saveAccessLevel: "Login/UpdateAccessLinkStatus",
    getAllActiveLinkName:"User/GetUserLinkNames",
  },
  client: {
    getClientDetails: "ClientPatient/GetClientDetails",
    estimateByClientId: "Estimates/GetEstimatesByClientId",
    clientStatus: "ClientPatient/UpdatePatientStatus",
    getAppointmentList: "Appointment/AppointmentByClientId",
    clientUpload: "ClientPatient/UploadClientImage",
    changePassword: "Login/ChangePassword",
    confirmAppiointment:"Appointment/AppointmentConfirmation"
  },
  physicians: {
    veterinaryByFacility: "Veterinarian/VeterinarianByFacility",
    getVetanary: "Estimates/GetAllClients",
    saveVeterinary: "Veterinarian/SaveVeterinarian",
    getSpeciality: "Speciality/GetAllSpeciality",
    getSpecialityDropdown: "Speciality/GetAllSpeciality",
    veternarianById: "Veterinarian/VeterinarianById",
    setVailability: "Schedule/VeterinarianScheduleAvailability",
    getAvailability: "Schedule/GetScheduleAvailability",
    veterinaryStatus:"Veterinarian/VeterinarianStatus",
  },
  appointments: {
    vetListBasedOndateTime:"Veterinarian/VeterinarianByDate",
    advancePayment:"AdvancePayment/SaveAdvancePayment",
    appointmentStatusCount: "Appointment/AppointmentStatusCount",
    addQuickPatient: "Appointment/SavePatient",
    saveUploads: "Appointment/SaveAppointmentUploads",
    saveBundles: "Appointment/SaveAppointmentBundle",
    GetAppointmentBundles: "Appointment/GetAppointmentBundles",
    GetAppointmentBundleItems: "Appointment/GetAppointmentBundleItems",
    getCheckList: "Appointment/GetCheckList",
    getCareteamRoles: "Appointment/GetRoles",
    getCareteamUsers: "Appointment/GetCareTeam",
    saveAppointment: "Appointment/SaveAppointments",
    getAllAppointments: "Appointment/GetAppointments",
    getAppointmentById: "Appointment/GetAppointmentsById",
    getEstimates: "Appointment/GetEstimatesByAppointmentId",
    updateEstimateItemsStatus: "Estimates/UpdateEstimateItemStatus",
    getInsuranceById: "Insurance/GetInsuranceById",
    getDropDown: "Appointment/GetAppointmentDropDowns",
    deleteUploads: "Appointment/DeleteAppointmentUploads",
    updateCheckListStatus: "Appointment/UpdateCheckListStatus",
    appointmentStatus: "Appointment/UpdateStatus",
    getOutPatientEstimate: "OutPatient/GetEstimateItemsforOutPatient",
    getOutPatientStock:"TBoard/CheckStock"
  },
  callender: {
    rooms: "Appointment/GetRooms",
    schedule: "Schedule/VeterinarianAvailability"
  },
  QuickAppointment: {
    appointmentType: "Appointment/GetAppointmentDropDowns",
    appointmentByFacilityByVeterinary: "Appointment/AppointmentsByFacility",
    getNextAvailability: "Appointment/GetNextAvailability",
    availabilityByDate:"Schedule/VeterinarianAvailabilityTime",
  },
  patientView: {
    forms: "Forms/FormsById",
    inbox: "Inbox/GetAllInbox",
    patientUpload: "ClientPatient/SavePatientUploads",
    getAlluploads: "ClientPatient/GetAllPatientUploads",
    deleteUploads: "ClientPatient/DeletePatientUploads",
    saveForm: "Forms/SaveForm",
    getLabs: "LabTests/GetLabsByPatient",
    saveMicrochip: "MicroChip/SaveMicroChip",
    getMicrochipList: "MicroChip/GetMicroChipByPatient",
    getFormById: "Forms/GetFormsById",
    saveClinicalForm: "AppointmentClinicalNotes/SaveClinicalNotes",
    getClinicalNotes: "AppointmentClinicalNotes/ClinicalNotesById",
    getEstimateTables: "Appointment/GetAppointmentEstimateItems",
    Robies: "Rabies/SaveRabies",
    getRobbies: "Rabies/RabiesById",
    oredrSetCategory:"OrderSetCategory/GetAllDropDown",
    orderSetSave:"Category/Save",
    orderSetGetList:"Category/GetById",
    oredrSetDropdown:"OrderSetCategory/GetAllDropDown",
    saveSmartNotification: "SmartNotifications/SavePatientSmartNotification",
    getTotalInvoiceAndPayment: "PatientInvoice/GetPatientTotalInvoicePayment",
    //dia
    diagnosticsItems:"Estimates/SearchEstimateItemsDiagnostics",
saveDiagnosticEstimate:"Estimates/SaveDiagnosticsEstimates",
//discharge items service
 treatmentPlanDischargedItems:"PatientInvoice/GetAppointmentTreatmentplanItems",
 savePrescription: "PatientPrescription/SavePatientPrescription",
    prescriptionById:"PatientPrescription/GetPatientPrescriptionByPatientId",
  prescriptiongetList:"",
  },

  dashbord: {

    treatmentplan: "Appointment/AppointmentByEstimatesById",
    saveTreatment: "TreatmentPlan/SaveTreatmentPlan",
    saveVitalSign: "TreatmentPlan/SavePatientVitalSigns",
    getAllTBoradPlan: "TBoard/GetAllTreatmentPlan",
    finalSubmitPlan: "TreatmentPlan/UpdateTreatmentPlanStatus",
    clientDetails: "ClientPatient/GetPatientDetails",
    getVitalSign: "TreatmentPlan/GetPatientVitalSigns",
    saveMoreEstimateItemsForOutPatient: "TBoard/SaveTreatmentPlan",//TBoard/SaveTreatmentPlanFrequency
    saveMoreEstimateItems:"TBoard/SaveTreatmentPlanFrequency",
    checkList:"Appointment/AppointmentByCheckList"
  },
  masters: {
    // appointment Type
    getAppointmentTypes: "AppointmentType/AppointmentTypeById",
    getAppointmentTypeList: "AppointmentType/GetAll",
    saveAppointmentType: "AppointmentType/SaveAppointmentType",
    appointmentTypeStatus: "AppointmentType/AppointmentTypeStatus",
    // CheckList
    getCheckList: "CheckList/GetAll",
    getCheckListById: "CheckList/CheckListById",
    saveCheckList: "CheckList/SaveCheckList",
    checkListStatus: "CheckList/CheckListStatus",
    getAllCheckList: "CheckList/GetAllList",
    // Species
    getSpeciesList: "Species/GetAllSpecies",
    saveSpecies: "Species/Save",
    getSpeciesById: "Species/SpeciesById",
    speciesStatus: "Species/UpdateStatus",
    getAllSpeciesByFacility: "Species/SpeciesById",
    
    // Category
    getCategoryList: "OrderSetCategory/GetAll",
    saveCategory: "OrderSetCategory/Save",
    categoryStatus: "OrderSetCategory/UpdateStatus",
    getCategoryById: "OrderSetCategory/GetById",
    // Breed
    getBreedList: "Breed/GetAllBreeds",
    getBreedById: "Breed/BreedById",
    saveBreed: "Breed/Save",
    breedStatus: "Breed/UpdateStatus",
    // Speciality
    getSpecialityList: "Speciality/GetAllSpeciality",
    saveSpeciality: "Speciality/SaveSpeciality",
    getSpecialityById: "Speciality/SpecialityById",
    specialityStatus: "Speciality/SpecialityStatus",
    // Rooms
    getRooms: "Rooms/RoomById",
    getRoomsList: "Rooms/GetAll",
    saveRooms: "Rooms/SaveRooms",
    updateRoomStatus: "Rooms/RoomStatus",
    // Attachments
    getAttachmentList: "AttachmentType/GetAll",
    saveAttachments: "AttachmentType/SaveAttachmentType",
    getAttachmentsById: "AttachmentType/AttachmentTypeById",
    attachmentStatus: "AttachmentType/AttachmentTypeStatus",
    // Unit of Measure
    getAllUom: "Uom/GetAll",
    saveUom: "Uom/SaveUom",
    getUomById: "Uom/UomById",
    UomStatus: "Uom/UomStatus",
    getAllUomDropDown: "Uom/GetAllDropdown",
    // Stock At Location
    getAllStockLocation: "StockLocation/GetAll",
    saveStockLocation: "StockLocation/SaveStockLocation",
    getStockLocationById: "StockLocation/StockById",
    stockLocationStatus: "StockLocation/StockStatus",
    getStockLocationsByFacility: "StockLocation/GetAllStoackLocation",
    // Organization
    getAllOrganizations: "Organization/GetAll",
    saveOrganization: "Organization/Save",
    getOrganizationById: "Organization/GetById",
    organizationStatus: "Organization/UpdateStatus",
    // Payment Terms
    getAllPaymentTerms: "PaymentTerms/PaymentTermsGetAll",
    savePaymentTerm: "PaymentTerms/SavePaymenTterms",
    getPaymentTermsById: "PaymentTerms/PaymentTermsGetById",
    paymentTermsStatus: "PaymentTerms/PaymentTermsStatus",
    getPaymentTermsDropDown: "PaymentTerms/PaymentTermsDropdown",
    // Payment Type
    getAllPaymentType: "PaymentType/GetAll",
    savePaymentType: "PaymentType/Save",
    getPaymentTypeById: "PaymentType/GetById",
    paymentTypeStatus: "PaymentType/UpdateStatus",
    getPaymentTypeDropDown: "PaymentType/GetAllDropDown",
    // Facility
    getAllFacilities: "Facility/GetAll",
    saveFacility: "Facility/SaveFacility",
    getFacilityById: "Facility/FacilityById",
    facilityStatus: "Facility/FacilityStatus",
    // Invoice Tax
    getAllInvoiceTax: "InvoiceTax/InvoiceTaxGetAll",
    saveInvoiceTax: "InvoiceTax/SaveInvoiceTax",
    getInvoiceTaxById: "InvoiceTax/InvoiceTaxGetById",
    invoiceTaxStatus: "InvoiceTax/InvoiceTaxStatus",
    getInvoiceTaxDropDown: "InvoiceTax/InvoiceTaxDropdown",
    // Tax Rules
    getAllTaxRules: "TaxRules/GetAll",
    saveTaxRules: "TaxRules/SaveTaxRule",
    getTaxRulesById: "TaxRules/TaxRuleById",
    taxRulesStatus: "TaxRules/TaxRuleStatus",
    getAllTaxRulesDropDown: "TaxRules/GetAllDropdown",
    // Service Type
    getAllServiceType: "ServiceType/GetAll",
    saveServiceType: "ServiceType/SaveServiceType",
    getServiceTypeById: "ServiceType/ServiceTypeById",
    serviceTypeStatus: "ServiceType/ServiceTypeStatus",
    getAllServiceTypeDropDown: "ServiceType/GetAllDropdown",
    // Inventory Container
    getAllInventoryContainer: "InventoryContainer/GetAll",
    saveInventoryContainer: "InventoryContainer/SaveInventoryContainer",
    getInventoryContainerById: "InventoryContainer/InventoryContainerById",
    inventoryContainerStatus: "InventoryContainer/InventoryContainerStatus",
    //smart Notifications
    getAllSmartNotifications: "SmartNotifications/GetAllSmartNotification",
    saveSmartNotification: "SmartNotifications/SaveSmartNotification",
    getSmartNotificationById: "SmartNotifications/SmartNotificationById",
    smartNotificationStatus: "SmartNotifications/UpdateNotificationStatus",
    // Notifications
    getAllNotifications: "SmartNotificationName/GetAll",
    saveNotification: "SmartNotificationName/Save",
    getNotificationById: "SmartNotificationName/GetById",
    notificationStatus: "SmartNotificationName/UpdateStatus",
    getNotificationDropDown: "SmartNotificationName/GetAllDropDown",
    // Tags
    getAllTags: "PatientlibraryTags/GetAll",
    saveTag: "PatientlibraryTags/Save",
    getTagById: "PatientlibraryTags/GetById",
    tagStatus: "PatientlibraryTags/UpdateStatus",
    getTagDropDown: "PatientlibraryTags/GetAllDropDown",

    //insurance
    getAllInsurances: "InsuranceMaster/GetAll",
    saveInsurance: "InsuranceMaster/Save",
    getInsuranceById: "InsuranceMaster/GetById",
    insuranceStatus: "InsuranceMaster/UpdateStatus",
    getInsuranceDropDown: "InsuranceMaster/GetAllDropDown"


  },
  inventory: {
    saveCategory: "Inventory/Save",
    getAllCategory: "Inventory/GetAll",
    getByIdCategory: "Inventory/GetById",
    getAllInventoryVendor: "Vendor/GetInventoryVendor",
    inventoryStatus: "Inventory/InventoryStatus",
    getBundleItems: "Inventory/InventoryCategoryList",
    getcopyListBundle: "/Inventory/InventoryCategoryPickList",
    // Primary Master
    getAllPrimaryMaster: "InventoryPrimary/GetAll",
    savePrimaryMaster: "InventoryPrimary/SavePrimary",
    getPrimaryMasterById: "InventoryPrimary/PrimaryById",
    primaryMasterStatus: "InventoryPrimary/InventoryPrimaryStatus",
    getPrimaryMasterDropDown: "InventoryPrimary/GetAllDropdown",
    // Sub Class
    getAllSubClass: "InventorySubClass/GetAll",
    saveSubClass: "InventorySubClass/SaveSubClass",
    getSubClassById: "InventorySubClass/SubClassById",
    subClassStatus: "InventorySubClass/InventorySubClassStatus",
    getAllPrimaryMasterByFacility: "InventorySubClass/SubClassPrimaryId",
    getSubClassByPrimaryId: "InventorySubClass/GetSubClassByPrimaryId",
    // Vendor
    getAllVendor: "Vendor/GetAll",
    saveVendor: "Vendor/SaveVendor",
    getVendorById: "Vendor/VendorById",
    vendorStatus: "Vendor/VendorStatus",
    getVendors: "Vendor/GetAllVendors",
    // Sales Tax
    getSalesTaxList: "SalesTax/GetAll",
    saveSalesTax: "SalesTax/Save",
    getSalesTaxById: "SalesTax/GetById",
    salesTaxStatus: "SalesTax/UpdateStatus",
    getAllSalesTax: "SalesTax/GetAllSalesTax",
    // Usage Tax
    getUsageTaxList: "UsageTax/GetAll",
    saveUsageTax: "UsageTax/Save",
    getUsageTaxById: "UsageTax/GetById",
    usageTaxStatus: "UsageTax/UpdateStatus",
    getAllUsageTax: "UsageTax/GetAllUsageTax",
    // Discount
    getDiscountList: "Discount/GetAllList",
    saveDiscount: "Discount/SaveDiscount",
    getDiscountById: "Discount/DiscountById",
    discountStatus: "Discount/DiscountStatus",
    getAllDiscount: "Discount/GetAll",
    getPreSetDiscount:"Discount/PreTaxDiscount",
    // Departing Instructions
    getInstructionsList: "DepartingInstruction/GetAllList ",
    saveInstructions: "DepartingInstruction/SaveDeparting",
    getInstructionsById: "DepartingInstruction/DepartingById",
    instructionsStatus: "DepartingInstruction/DepartingStatus",
    getAllInstructions: "DepartingInstruction/GetAll ",
    //Special Actions 
    getSpecialActionList: "SpecialAction/GetAll",
    saveSpecialAction: "SpecialAction/Save",
    getSpecialActionById: "SpecialAction/GetById",
    specialActionStatus: "SpecialAction/UpdateStatus",
    getAllSpecialActions: "SpecialAction/GetAllDropDown",

    //When To Apply
    getWTAList: "WhenToApply/GetAll",
    saveWTA: "WhenToApply/Save",
    getWTAById: "WhenToApply/GetById",
    WTAStatus: "WhenToApply/UpdateStatus",
    getAllWTADropDown: "WhenToApply/GetAllDropDown",
    //travelsheet
    saveTraveSheet: "TravelSheet/Save",
    getAllTravelSheet: "TravelSheet/GetAll",
    getByIdTravelSheet: "TravelSheet/GetById",
    travelSheetStatus: "TravelSheet/UpdateStatus",
    getAllTravelSheetDropDown: "TravelSheet/GetAllDropDown",
    // Vaccine List
    saveVaccineList: "VaccineAdmin/Save",
    getAllVaccineList: "VaccineAdmin/GetAll",
    getVaccineListById: "VaccineAdmin/GetById",
    VaccineListStatus: "VaccineAdmin/UpdateStatus",
    getAllVaccineDropDown: "VaccineAdmin/GetAllDropDown",
    // Patient Gender
    savePatientGender: "PatientSex/Save",
    getAllPatientGenderList: "PatientSex/GetAll",
    getPatientGenderById: "PatientSex/GetById",
    PatientGenderStatus: "PatientSex/UpdateStatus",
    getAllPatientGenderDropDown: "PatientSex/GetAllDropDown",
    //bundleItems
    deleteBundle: "BundleItem/DeleteInventoryBundleItems",
    getByIdBundleItems: "Inventory/GetBundleItemPopup",
    //Inventory Purchase Order
    getAllPurchaseOrder: "InventoryPurchase/PurchaseOrderGetAll",
    getInventoryList: "InventoryPurchase/InventoryList",
    inventoryOrderGetById: "InventoryPurchase/OrderGetById",
    savePurchaseOrder: "InventoryPurchase/SavePurchaseOrder",
    orderUpdateStatus: "InventoryPurchase/OrderUpdateStatus",
    getPurchaseOrderItemsById: "InventoryPurchase/GetPurchaseOrderByInventoryItemId",
    getEmailList: "Vendor/EmailByVendor",
    getOrderReceiptsList: "InventoryPurchase/PurchaseReceiptByOrderId",

    //Calculator
    saveCalulator:"Inventory/SaveCalculator",

    //Inventory Purchase Order
    getAllReceipts: "InventoryPurchase/PurchaseReceiptGetAll",
    receiptsGetById: "InventoryPurchase/ReceiptGetById",
    getInventoryListByVendorId: "InventoryPurchase/ReceiptByVendor",
    savePurchaseReceipt: "InventoryPurchase/SavePurchaseReceipt",
    receiptsUpdateStatus: "InventoryPurchase/ReceiptUpdateStatus",
    getReceiptItemsById: "InventoryPurchase/GetPurchaseReceiptByInventoryItemId",
    sentEmail: "InventoryPurchase/OrderSendEmail",
    logBalance: "Inventory/InventoryLogBalance",
  },
  invoice: {
    // Vendor Invoice
    getAllVendorInvoice: "Invoice/VendorInvoiceGetAll",
    getInventoryItemsByVendorId: "Invoice/PurchaseOrderItemsByVendor",
    getPurchaseOrdersByItemId: "Invoice/PurchaseOrderByInventoryItem",
    saveVendorInvoice: "Invoice/SaveVendorInvoice",
    vendorInvoiceGetById: "Invoice/VendorInvoiceGetById",
    vendorInvetoryGetbyItemId: "Invoice/VendorInvoiceItemById",
    vendorUploadAttachment: "Invoice/VendorInvoiceUploads",
    //vendor payment
    saveVendorPayment: "VendorPayment/SaveVendorPayment",
    vendorPaymentGetAll: "VendorPayment/VendorPaymentGetAll",
    getvendorInvoiceByvendorId: "VendorPayment/VendorInvoiceByVendorId",
    vendorPaymentById: "VendorPayment/VendorPaymentGetById",
    vendorPaymentItemId: "VendorPayment/VendorPaymentItemById",
    vendorPaymentItemById: "VendorPayment/VendorPaymentItemById",

  },
  patientIvoice: {
    savepatientInvoice: "PatientInvoice/SavePatientInvoice",
    patientItemList: "PatientInvoice/EstimatesByInvoicePatient",
    patientInvoiceGetById: "PatientInvoice/PatientInvoiceGetById",
    getAllPatientInvoice: "/PatientInvoice/PatientInvoiceGetAll",
    getByIdInvoiceItems: "/PatientInvoice/PatientInvoiceItemById",
    getPatientAppointment: "/Appointment/GetPatientAppointments",
    getAllItemsByFacility: "PatientInvoice/PatientInventoryItems",
    getAllTaxInvoice: "InvoiceTax/InvoiceTaxDropdown",
    getClientsByappoinmentdischarge:"PatientPayment/PatientPaymentDropDown",
    savePatientPayment: "PatientPayment/SavePatientPayment",
    patientGetAllInvoices: "PatientPayment/PatientInvoiceByPatientId",
    patientGetByPaymentId: "PatientPayment/PatientPaymentGetById",
    patientPaymentGetAll: "PatientPayment/PatientPaymentGetAll",
    patientPaymentItemId: "PatientPayment/PatientPaymentItemById",
    patientPaymentList: "PatientPayment/PatientPaymentByPatientId",
    getPatientInvoiceList: "PatientInvoice/PatientInvoicePayment",
    saveBundleItems:"PatientInvoice/SavePatientInvoiceBundlePick",
    deleteBundle:"PatientInvoice/DeletePatientInvoiceItem",
    patientInvoiceAppoinmentDropdownById:"Appointment/GetAppointmentsByPatient"
  },
  admin: {
    users: {
      UserCreate: "User/SaveUser",
      userGetById: "User/UserById",
      userGetAll: "User/UserGetAll"
    }
  },
  patientLibrary: {
    CreateMedia: "PatientLibrary/Save",
    MediaGetById: "PatientLibrary/GetById",
    MediaGetAll: "PatientLibrary/GetAll",
    getClientDetails: "PatientLibrary/GetClientMediaFiles",
    shareLibrary: "PatientLibrary/Share"
  },

  chart: {
    getAllClients: "PatientChat/GetAllChatUsers",
    setMessage: "PatientChat/Save",
    chartGetById: "PatientChat/GetById",
    messageCount: "PatientChat/MessageCount",
    ClientByIdChart: "PatientChat/ClientChatList",
    unreadMessage: "PatientChat/MessageStatus",
    getChatCountList: "PatientChat/UnreadUserChat",
    getchatMessageCount:"PatientChat/MessageCount"

  },
  inbox: {
    getSentUsers: "Inbox/GetSentUsers",
  },
  settings:{
    getSettingsById:"Settings/GetOrganizationSettings",
    SaveOrganizationSettings:"Settings/SaveOrganizationSettings"
  }



};
