export const UpdateAccessLinksAction = () =>({
    type:"UPDATEACCESSLINKS"
})
export const logOut = () =>({
    type:"LOGOUT"
})
export const facilityGlobaly = (payload) =>({
    type:"FacilityGlobally",
    data:payload
})

export const updateMessages = (payload) =>({
    type:"UPDATE_MESSAGES",
    data:payload
})
export const updateUserId = (payload) =>({
    type:"UPDATE_USERID",
    data:payload
})


