
import instance from "../services"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyInput from 'react-currency-input-field';
import CryptoJS from "crypto-js";
import { Base_Url, Image_Base_Url, urls } from "./url";
import { HiChevronLeft } from "react-icons/hi"
import moment from "moment";
import axios from "axios";
export const notify = (status, msg) => {
if (status == true) {
        toast.success(msg)
        return (<ToastContainer />)
    } else {
        toast.error(msg)
        return (<ToastContainer />)
    }
}
export const checkingAccess=(key)=>{
   
    if(!key){
     return   forbiddenFunction()
    }
 
}
export const masterFacility=(array,id)=>{
    let obj=array?.filter((v)=>v?.value==id)
   return obj

}
export const handleFilesDownload = async (folderName,fileName,actualFileName) => {
    const fileURL = `${Image_Base_Url}/${folderName}/${fileName}`;
    const response = await fetch(fileURL);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = actualFileName;
    
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  };
export const decryPtiedLinks = () => {
    let links = sessionStorage.getItem("accessLinks");
    if (links && links != undefined && links != "") {
      var bytes = CryptoJS.AES.decrypt(links, "Schedyo123");
   
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } else {
      return [];
    }
  };
  export const CheckLink = (linkName) => {
    return decryPtiedLinks()?.includes(linkName);
  };
  export const sessionLogOut = () => {
   return window.location.pathname = "/";
   };
  export const forbiddenFunction = () => {
    sessionStorage.clear();
    window.location.pathname = "/forbidden";
  };

  export const filterDropdown=(array,key,label,ele,description)=>{
    let list=[...ele]
    let arr=list?.map((v)=>{
        return v?.[key]
    })
    let fil=array?.filter((v)=> !arr?.includes(v[key]) )
return fil?.map((v)=>{return({label:v?.[description]&&v?.[description]!=undefined?v?.[label]+" "+"["+v?.[description]+"]":v?.[label],value:v?.[key]})})
  }


export const sortingTable=(a,b,key)=>{
   
        const nameA = a?.[key]?.toUpperCase();
        const nameB = b?.[key]?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;     
}

export const sortingTableNumbers=(a,b,key)=>{
   const nameA = a?.[key]?.toString()?.toUpperCase();
    const nameB = b?.[key]?.toString()?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; 
}
export const customStyles = {
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
      }
    },
    rows: {
      style: {
       marginLeft:"0px",
      
        
      }
    },
    
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight: '600',
        textTransform: 'uppercase',
        fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
        backgroundColor:"#C7EEE6",
            color:"green",
            marginLeft:"0px",
        //     background: "transparent linear-gradient(92deg, rgba(246, 245, 248, 1) 0%, rgba(226, 242, 240, 1) 42%, rgba(199, 238, 230, 1) 64%, rgba(228, 214, 240, 1) 100%) 0% 0% no-repeat padding-box",
        // opacity: 1,
      },
    },
    cells: {
      style: {
        fontSize: '14px',
       fontFamily:'-apple-system, BlinkMacSystemFont',
       fontFamily: "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#212529",
   
    width:"8px",
    
    
      },
    },
    
  };
export const add = async (url, data) => {
    
    try {
        let res = await instance.post(url, data);
       
        return res;
    } catch (e) {
       
        return {}
    }
}
export const filterDropDownFun=(search,data,setState,itemName,type,displayName)=>{
   
    if(search&&search!=""){
        let res=data?.filter((v)=>(v?.[itemName]?.toLowerCase().includes(search?.toLowerCase())||
        v?.[type]?.toLowerCase().includes(search?.toLowerCase())||v?.[displayName]?.toLowerCase().includes(search?.toLowerCase())))
        setState(res)
    }
    else{
        setState(data)
    }
    
}
export const returnLocationFacility = (array, key, value, data,facilityName) => {
    let filterObject = (array?.filter((v) => v?.[key] == data))[0]
    return filterObject?.[value] != undefined ? { label: filterObject?.[value]+" ("+filterObject?.[facilityName]+")", value: filterObject?.[key] } : ""
  }
  export const chatServices=async(url,jsonString)=>{
    let headers = {"Content-Type": "application/json",Authorization: "Bearer " + sessionStorage.getItem("token"),};
  try{
    let res = await axios.post( Base_Url + url,{ jsonString }, { headers } );
    if (res?.data?.status == true) {
        return res
      } else {
        return []
      }
  }
  catch(e){
return e
  }
  }
export const getAll = async (url) => {
    try {
        let res = await instance.get(url);
        
        return res.data.data;
    } catch (e) {

        return {}
    }
}
export const getList = async (url, data) => {
    try {
        const res = await instance.post(url, data);
        if (res?.data?.status == true) {
            return res?.data?.data
        } else {
            return []
        }
    } catch (e) {
        return []
    }
}
export const download = async (payload) => {
    const jsonString =payload
    const res = await save(urls.reports.downloadReports, { jsonString })
    
    if(res?.data?.status==true){
        const fileURL = `${Image_Base_Url}/Reports/${payload.type}.pdf`;
    const response = await fetch(fileURL);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${payload?.type}.pdf`;
    
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
       
    }
  
  }
  export const downloadforReports = async (payload) => {
    const jsonString =payload
    const res = await save(urls.reports.downloadReportsforReports, { jsonString })
    
    if(res?.data?.status==true){
        const fileURL = `${Image_Base_Url}/Reports/${payload.type}.pdf`;
    const response = await fetch(fileURL);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = `${payload?.type}.pdf`;
    
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
       
    }
  
  }
  export const sendEmail = async (payload) => {
    const jsonString =payload
     await save(urls.reports.downloadReports, { jsonString })
  }
  
export const getById = async (url, data) => {
    try {
        const res = await instance.post(url, data);
        if (res?.data?.status == true) {
            return res?.data?.data
        } else {
            return {}
        }
    } catch (e) {
        return {}
    }
}

export const getfList = async (url) => {
    try {
        const res = await instance.get(url);
        if (res?.data?.status == true) {
            return res?.data?.data
        } else {
            return []
        }
    } catch (e) {
        return []
    }
}
export const getSchedule = async (url, data) => {
    try {
        const res = await instance.post(url, data)

        if (res?.data?.status == true) {
            const resdata = res?.data?.data;

            resdata?.map((element) => {
                element["id"] = element?.ScheduleId

                element["end"] = element?.EndActivity 

                element["start"] = element?.StartActivity 
                element["resource"] = element?.VeterinarianId
                

                if (element.activity == "Available") {
                    element["barColor"] = "#0F497F"
                   
                } else {
                    element["barColor"] = "#088772"
                }
            })
            return resdata;
        } else {
            return []
        }
    } catch (e) {
        return []
    }
}

export const getScheduleTborda = async (url, data) => {
   
    try {
        const res = await instance.post(url, data)

        if (res?.data?.status == true) {
            const resdata = res?.data?.data;

            resdata?.map((element) => {
                element["id"] = element?.treatmentPlanId
                element["end"] =element?.startTime

                element["text"] =element?.itemDescription
                
                element["start"] =element?.startTime
                // element["start"]=element?.frequencyTime!="00:00:00"?moment(element.date).format("YYYY-MM-DD")+"T"+element.frequencyTime:element.startTime
             
                element["resource"] = element?.appointmentId
                element["toolTip"]=element?.itemDescription
                
                switch(element?.status){
                    case "Not Completed": return element["backColor"] = "#ffff000"
                    case "Completed": return element["backColor"] = "#00FFFF"
                    case "Overdue": return element["backColor"] = "#FFFF00"
                    case "Not Needed": return element["backColor"] = "#808080"
                    default :return element["backColor"] = "#ffff000"
                }
                
            })
            return resdata;
        } else {
            return []
        }
    } catch (e) {
        return []
    }
}
export const save = async (url, data) => {
    try {
        let res = await instance.post(url, data);
       
        if (res?.data?.status == true) {
           
            notify(res?.data?.status, res?.data?.message)
           
            return res
        } else {
            notify(res?.data?.status, res?.data?.message)
            return {}
        }
    } catch (e) {
       
        return {}
    }
}

export const addId = async (url, id) => {
    try {
        let res = await instance.post(url, id);
        return res;
    } catch (e) {

        return {}
    }
}
export const getStructuredRoomList = async (url, data) => {
    try {
        let res = await instance.post(url, data)
        let room = res?.data?.data
        let rooms = [];
        room?.map((e) => {
            let obj = {};
            obj['name'] = e.roomName;
            obj['id'] = e.roomId;
            rooms.push(obj)
        })
        return rooms
    } catch (e) {
        return []
    }
}

export const getAppointmentsList = async (url, data) => {
    
    try {
        let res = await instance.post(url, data)
        if (res?.data?.status == true) {
           
            let resdata = res?.data?.data.filter((v)=>v?.startTime !=null || v?.endTime!=null);
            resdata?.map((element) => {
                element["start"] = element.startTime
                element["end"] = element.endTime
                element["text"] = "Dr. " + element.veterinarianName + " ," + element.patientName
                element["backColor"] = element.colorCode
                element["resource"]=element?.roomId
               
                
element["html"]=`${"<div>"}${element?.isConfirmed=="Yes"?`<i class='fa fa-check 'style="font-size:20px;color:#01693B"></i>`:`<i></i>`}  ${"<b>"} Dr. ${element.veterinarianName + " ," + element.patientName}${"</b>"}${"</div> "}`

            })
            return resdata;
        } else {
            return []
        }
    } catch (e) {
        return []
    }
}

export const checkedFn = (data, value) => {
return data && data == value ? true : false

}


export const sliceFunction = (value) => {
    return value?.length > 30 ? value?.slice(0, value?.slice(0, 30).lastIndexOf(' ')) + "..." : value
}

export const returnValue = (value) => {
    return (value && value != "" && value != undefined) ? value : "";
};
export const phoneFormat = (phoneNumber) => {
    return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
}
export const phoneNumberValue = (value) => {
    return (value && value != '') ? phoneFormat(value) : ""
}
export const dollorFormart=(handleRateChange,returnValue,key,placeholder,errorCss)=>{
    return <CurrencyInput  allowNegativeValue={false}
    className={errorCss(key)}
    id="floatingInput"
    name={key}
    value={returnValue(key)}
    placeholder={placeholder}
    prefix="$"
    
    decimalsLimit={2}
    
    onValueChange={(value, name) =>
        handleRateChange(value, name)}/>
      }
      export const MultipledollorFormart=(handleRateChange,returnValue,key,placeholder)=>{
        return <CurrencyInput  allowNegativeValue={false}
        className="form-control bg-white"
        id="floatingInput"
        name={key}
        value={returnValue(key)}
        placeholder={placeholder}
        prefix="$"
        
        decimalsLimit={2}
        
        onValueChange={(value, name) =>
            handleRateChange(value, name)}/>
          }





