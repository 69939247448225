import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { add, getList, notify, save } from "../../configFiles/sharedFuctions";
import { urls } from "../../configFiles/url";
import { UseFormValidations } from "../validations/UseFormValidations";
import ForgotPassword from "./ForgotPassword";
import DogImg from "../../assets/images/vms_Dog.png";
import { useDispatch } from "react-redux";
import { updateUserId } from "../ReduxStore/Action";

const Login = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [forgot, setForgot] = useState(false);
  const navigate = useNavigate();

  const submit = async () => {
    let res = await save(urls.authenticate.login, data);
    if (res?.data.status == true) {
      dispatch(updateUserId(res?.data?.data?.Table?.UserId));
      sessionStorage.setItem("token", res?.data.token);
      sessionStorage.setItem("userId", res?.data?.data?.Table?.UserId);
      sessionStorage.setItem("facilityGroup", res?.data?.data?.Table?.GroupId);
      sessionStorage.setItem("facilityId", res?.data?.data?.Table?.FacilityId);
      sessionStorage.setItem("roleId", res?.data?.data?.Table?.RoleId);
      sessionStorage.setItem("firstName", res?.data?.data?.Table?.FirstName);
      sessionStorage.setItem("lastName", res?.data?.data?.Table?.LastName);
      sessionStorage.setItem(
        "clientImage",
        res?.data?.data?.Table?.clientImage
      );
      sessionStorage.setItem(
        "OrganizationId",
        res?.data?.data?.Table?.OrganizationId
      );
      sessionStorage.setItem("ClientId", res?.data?.data?.Table?.ClientId);
      sessionStorage.setItem(
        "subscriptionId",
        res?.data?.data?.Table?.SubscriptionId
      );
      sessionStorage.setItem("roleName", res?.data?.data?.Table?.roleName1);
      let RoleId = res?.data?.data?.Table?.RoleId;
      if (RoleId == 5) {
        navigate("/veterinary/client/dashboard");
      } else {
        navigate("/veterinary");
      }
    } else {
      notify(res?.data.status, res?.data.message);
    }
  };
  useEffect(() => {
    sessionStorage?.clear();
  }, []);
  const {
    data,
    errors,
    handleChange,
    handleEmailChange,
    handleSubmit,
  } = UseFormValidations({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: {
      userName: {
        required: {
          value: true,
          message: "Please enter  email id",
        },
        pattern: {
          value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
          message: "Please enter a valid email id",
        },
      },
      password: {
        required: {
          value: true,
          message: "Please enter  password",
        },
      },
    },
    submit: submit,
  });

  return (
    <div className="container-fluid loginpagebg">
      <div className="row ">
        <div className="col-md-6 d-flex justify-content-end ">
          <img
            src={DogImg}
            className="img"
            style={{ maxHeight: "46.7rem" }}
          ></img>
        </div>
        <div className="col-md-6   d-flex justify-content-center  d-flex align-items-center ">
          <div className=" p-4 col-md-7 border shadow rounded ">
            {forgot == false ? (
              <form onSubmit={handleSubmit} className="form-login">
                <div>
                  <label>Email Address</label>
                  <input
                    type="email"
                    className="form-control bg-white"
                    placeholder="Enter Email"
                    id="email"
                    autoComplete="off"
                    name="userName"
                    onChange={handleEmailChange("userName")}
                  />
                  <div>
                    {errors && errors.userName && (
                      <p className="text text-danger">{errors.userName}</p>
                    )}
                  </div>
                </div>

                <div>
                  <label>Password</label>
                  <div>
                    <input
                      type={!show ? "password" : "text"}
                      className="form-control bg-white  "
                      autoComplete="off"
                      name="password"
                      onChange={handleChange("password")}
                      placeholder="Enter Password"
                      id="pwd"
                    />
                    {data &&
                      data?.password != "" &&
                      (!show ? (
                        <span
                          className="fa fa-eye-slash eye-icon pointer"
                          style={
                            errors && errors.userName
                              ? { marginTop: "40px" }
                              : { marginTop: "-2px" }
                          }
                          aria-hidden="true"
                          onClick={() => setShow(!show)}
                        ></span>
                      ) : (
                        <i
                          className="fa fa-eye eye-icon pointer"
                          style={
                            errors && errors.userName
                              ? { marginTop: "40px" }
                              : { marginTop: "-2px" }
                          }
                          aria-hidden="true"
                          onClick={() => setShow(!show)}
                        ></i>
                      ))}
                  </div>
                  <div>
                    {errors && errors.password && (
                      <p className="text text-danger">{errors.password}</p>
                    )}
                  </div>
                </div>

                <div className="form-group ">
                  <label className="form-check-label">
                    <div onClick={() => setForgot(true)} className="pointer">
                      <span>Forgot Password?</span>
                    </div>
                  </label>
                </div>
                <div align="center">
                  <button type="submit" className="btn button btn-block">
                    Submit
                  </button>
                </div>
              </form>
            ) : (
              <ForgotPassword setForgot={setForgot} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
