import React, { useEffect, useState } from "react";

import {useNavigate } from "react-router-dom";
import {save } from "../../configFiles/sharedFuctions";
import { urls } from "../../configFiles/url";
import { UseFormValidations } from "../validations/UseFormValidations";

const ForgotPassword = (props) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  

  const submit = async () => {
    let jsonString=data
    await save(urls.authenticate.forgotPassword, {jsonString});
   
  };
useEffect(()=>{
  sessionStorage?.clear()
},[])
  const {
    data,
    errors,
    handleChange,
    handleSubmit,
  
  } = UseFormValidations({
    initialValues: {
        email: "",
     
      
    },
    validationSchema: {
        email: {
        required: {
          value: true,
          message: "Please enter  email id",
        },
        pattern: {
          value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
          message: "Please enter a valid email id",
        },
      },
     
    
      
    },
    submit: submit,
  });



  
  return (
    <>
      <form onSubmit={handleSubmit}>
            <div className="form-login">
              <div align="center">
                <img src="" />
              </div>

              <div className="form-group form-floating">
                <label htmlFor="floatingInput">Email Address</label>
                <input
                  type="email"
                  className="form-control bg-white"
                  placeholder="Enter Email"
                  id="email"
                  name="userName"
                  onChange={handleChange("email")}
                />
              </div>
              <div className="d-flex mr_25">
                {errors && errors.email && (
                  <p className="text text-danger">{errors.email}</p>
                )}
              </div>
              
              <div className="form-group mt-4 ">
                <label className="form-check-label">
                  <div  onClick={()=>props?.setForgot(false)} className="pointer">
                    <span>Login?</span>
                  </div>
                </label>
              </div>
             
              <div align="center ">
                <button type="submit" className="btn button btn-block">
                  Submit
                </button>
              </div>
            </div>

        
      </form>
    </>
  );
};

export default ForgotPassword;
