const initalState = {
  accessLinks: true,
  logOut: true,
  facilityGlobally: "",
  messages: [],
  loggedInUserId: sessionStorage?.getItem("userId") || 0,
};
export const Reducer = (state = initalState, action) => {
  switch (action.type) {
    case "UPDATEACCESSLINKS":
      return { ...state, accessLinks: !state.accessLinks };
    case "LOGOUT":
      return { ...state, logOut: !state.logOut };
    case "FacilityGlobally":
      return { ...state, facilityGlobally: action?.data };
    case "UPDATE_MESSAGES":
      return { ...state, messages: action?.data };
      case "UPDATE_USERID":
        return { ...state, loggedInUserId: action?.data };
    default:
      return state;
  }
};
